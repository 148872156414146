import { Translations } from '../translations.types';

export const hu: Translations = {
  flag: 'Zászló',
  shape: 'Alak',
  'Population & Currency': 'Népesség és Valuta',
  'Capital flag': 'Főváros',
  emblem: 'Jelkép',
  neighbours: 'Szomszédok',
  placeholder: 'Ország\u200by, terület...',
  guess: 'Tipp',
  share: 'Eredmények megosztása',
  'unknown-country': 'Ismeretlen ország!',
  copy: 'Eredmények másolva a vágólapra',
  'play-bonus': 'Bónuszkör játszása',
  'next-bonus': 'Következő bónuszkör',
  'other-games': 'További játékaink:',
  'submit-feedback': 'Visszajelzés küldése',
  'remaining-guesses': '{{attemptsLeft}} próbálkozásod maradt',
  main: {
    'make-a-guess': 'Tegyél egy tippet, hogy felfedd az első lapot',
    country: 'Ország...',
    flag: 'Zászló',
    'already-guessed': 'Már kitaláltad: {{currentGuess}}',
  },
  quiz: {
    title: 'Népesség és Valuta',
    'estimated-population': 'Mi {{countryName}} becsült népessége?',
    'guess-currency': 'Milyen valutát használnak {{countryName}}-ban/-ben?',
    population: 'Népesség',
    currency: 'Valuta',
    micro: {
      'less-25': 'Kevesebb, mint 25',
      over: 'Több, mint 2000',
    },
    small: {
      'first-option': '< 50 ezer',
      'second-option': '50 - 500 ezer',
      'third-option': '500k - 1 millió',
      'fourth-option': '1+ millió',
    },
    medium: {
      'first-option': '< 3 millió',
      'second-option': '3 - 5 millió',
      'third-option': '5 - 8 millió',
      'fourth-option': '8+ millió',
    },
    large: {
      'first-option': '< 15 millió',
      'second-option': '15 - 25 millió',
      'third-option': '25 - 35 millió',
      'fourth-option': '35+ millió',
    },
    massive: {
      'first-option': '< 60 millió',
      'second-option': '60 - 80 millió',
      'third-option': '80 - 100 millió',
      'fourth-option': '100+ millió',
    },
  },
  'shape-round': {
    title: 'Mi {{countryName}} az alakja',
    sponsored: 'Szponzorálta',
    like: 'Tetszik ez a kör',
    try: 'Próbáld ki',
  },
  'emblem-round': {
    title: 'Válaszd ki {{countryName}} nemzeti jelképét',
  },
  'capital-flag': {
    title: 'Válaszd ki {{country}} fővárosának zászlaját: {{city}}',
  },
  border: {
    title: 'Válaszd ki egy {{countryName}} szomszédos ország zászlaját',
  },
  settings: {
    title: 'Beállítások',
    'distance-unit': 'Távolság mértékegysége',
    theme: 'Téma',
    language: 'Nyelv',
    'difficulty-modifiers': 'Nehézségi módosítók',
    grayscale: 'Szürkeárnyalatos zászló',
  },
  stats: {
    title: 'Statisztikák',
    played: 'Játszott',
    win: 'Győzelmi arány',
    currentStreak: 'Jelenlegi sorozat',
    maxStreak: 'Leghosszabb sorozat',
    guessDistribution: 'Tippeloszlás',
    donate: '🇺🇦 Adományozz Ukrajnának ❤️',
  },
  'how-to-play': {
    title: 'Játékszabályok',
    guess: 'Találd ki a',
    'number-guesses': '6 próbálkozásból.',
    'valid-country':
      'Minden tippnek egy érvényes országnak vagy területnek kell lennie.',
    'after-guess':
      'Minden tipp után megkapod a távolságot, az irányt és a közelséget a célhelyhez képest.',
    examples: 'Példák',
    'your-guess': 'A tipped',
    is: 'van',
    away: 'messze a célhelytől, északkeleti irányban, és csak 32%-os közelség, mert elég távol van.',
    'second-guess': 'A második tipped',
    'getting-closer':
      'közelebb van! {{distance}} távolság, délkeleti irányban, 83%-os közelséggel!',
    'next-guess': 'Következő tipp,',
    'right-guess': 'ez a kitalálandó helyszín! Gratulálok!',
    'new-game': 'Egy új',
    available: 'naponta elérhető lesz!',
    suggestions: 'Kérdés vagy javaslat?',
    check: 'Tekintsd meg a',
    faq: 'Flagle GYIK-et',
    made: 'Készítette',
    ads: 'Hirdetések',
    privacy: 'Adatvédelmi irányelvek',
    feedback: 'Visszajelzés',
    hiring: 'Munkatársakat keresünk',
  },
  faq: {
    'when-new-game-available': {
      question: 'Mikor érhető el az új játék?',
      answer:
        'Egy új játék minden nap elérhető 0:00 órától, az eszközöd helyi ideje szerint!',
    },
    'how-earn-bonus-round': {
      question: 'Hogyan juthatok el a következő bónusz körbe?',
      answer:
        'Ahhoz, hogy megszerezd az első bónusz kört (alakzat), egyszerűen helyesen kell kitalálnod az országot.',
    },
    'why-data-incorrect': {
      question: 'Miért hibásak vagy elavultak az adatok?',
      answer:
        'Arra törekszünk, hogy adatainkat a lehető leggyorsabban és legpontosabban frissítsük. Tudjuk, hogy a határok változnak, a fővárosok elmozdulnak, és a térképek elavulnak. Bármilyen hiba teljesen szándékolatlan, és nem jelent politikai állásfoglalást. Nagyra értékeljük a visszajelzéseket, és kijavítjuk a hibákat, amint tudomásunkra jutnak. Az idő előrehaladtával egyre kevesebb hiba lesz.',
    },
    'green-yellow-squares': {
      question: 'Mit jelentenek a zöld és sárga négyzetek?',
      answer:
        'Ezek a közelségi százalékot jelzik:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'Mi az a közelségi százalék?',
      answer:
        'Ez egy másik módja a távolság kifejezésének.<br><br>Ha a tipped a világ másik oldalán van, akkor 0%-ot kapsz.<br>Ha pontosan a célponton van, 100%-ot kapsz!',
    },
    'how-distances-computed': {
      question: 'Hogyan számítják ki a távolságokat?',
      answer:
        'A megjelenített távolságok a kiválasztott és a célterület központjai közötti távolságot jelzik.<br><br>Például az Egyesült Államok és Kanada közötti számított távolság körülbelül 2260 km, még akkor is, ha közös határuk van.<br><br>Két ok a választásra:<br><br>• Technikailag sokkal könnyebb így kiszámítani a távolságot, mint a legközelebbi határokat használva.<br><br>• Ezzel az információval következtetni lehet a célország méretére.',
    },
    'why-not-valid-answer': {
      question: 'Miért nem érvényes válasz egy adott ország?',
      answer:
        'Az ISO 3166-1 szabványt használom az országkód lista referenciájaként.<br><br>Például Skócia nem érvényes válasz, mert a szabvány szerint ez az ország az Egyesült Királyság része.',
    },
    'how-countries-picked': {
      question:
        'Hogyan választják ki az országokat és területeket? Mi történik, ha már minden országot kiválasztottak?',
      answer:
        'Minden nap véletlenszerűen kiválasztanak egy országot vagy területet!<br><br>Tehát minden nap folytatja a véletlenszerű választást, örökké!<br><br>Két szabályt adtak hozzá:<br><br>• Nem választ ki 5000 km²-nél kisebb országot vagy területet, ha az elmúlt 7 napban már választottak egyet.<br>• Nem választ ki olyan országot, amelyet az elmúlt 100 napban már kiválasztottak.<br>• Nem választ ki az előző nap kontinenséről származó országot (csak Afrika és Ázsia esetében érvényes).',
    },
    'why-ads': {
      question: 'Miért látok hirdetéseket az eszközömön?',
      answer:
        'A hirdetéseket az új funkciók fejlesztési költségeinek, valamint a tárhely és egyéb kiadások fedezésére használjuk. Az évek során @teuteuf sok javaslatot és kérést kapott, amelyeket nem tudott teljesíteni. Több funkciót és szórakoztatóbb játékmenetet szeretnénk biztosítani NEKED. A tervünk, hogy hivatalos mobilalkalmazást és számtalan bónuszfunkciót hozzunk létre, hogy a Worldle továbbra is a legszórakoztatóbb földrajzi kvízjáték legyen az interneten!',
    },
  },
};
