import { Translations } from '../translations.types';

export const ja: Translations = {
  flag: '旗',
  shape: '形状',
  'Population & Currency': '人口と通貨',
  'Capital flag': '首都',
  emblem: '紋章',
  neighbours: '隣国',
  placeholder: '国\u200b、地域...',
  guess: '推測する',
  share: '結果を共有する',
  'unknown-country': '不明な国です！',
  copy: '結果をクリップボードにコピーしました',
  'play-bonus': 'ボーナスラウンドをプレイする',
  'next-bonus': '次のボーナスラウンド',
  'other-games': '他のゲーム：',
  'submit-feedback': 'フィードバックを送信',
  'remaining-guesses': '残りの試行回数は{{attemptsLeft}}回です',
  main: {
    'make-a-guess': '最初のタイルを表示するには推測してください',
    country: '国...',
    flag: '旗',
    'already-guessed': 'すでに{{currentGuess}}を推測しました',
  },
  quiz: {
    title: '人口と通貨',
    'estimated-population': '{{countryName}}の推定人口は？',
    'guess-currency': '{{countryName}}で使用されている通貨は？',
    population: '人口',
    currency: '通貨',
    micro: {
      'less-25': '25未満',
      over: '2000以上',
    },
    small: {
      'first-option': '< 5万',
      'second-option': '5万 - 50万',
      'third-option': '50万 - 100万',
      'fourth-option': '100万以上',
    },
    medium: {
      'first-option': '< 300万',
      'second-option': '300万 - 500万',
      'third-option': '500万 - 800万',
      'fourth-option': '800万以上',
    },
    large: {
      'first-option': '< 1500万',
      'second-option': '1500万 - 2500万',
      'third-option': '2500万 - 3500万',
      'fourth-option': '3500万以上',
    },
    massive: {
      'first-option': '< 6000万',
      'second-option': '6000万 - 8000万',
      'third-option': '8000万 - 1億',
      'fourth-option': '1億以上',
    },
  },
  'shape-round': {
    title: '{{countryName}}の形状は？',
    sponsored: '提供：',
    like: 'このラウンドを気に入った',
    try: '試してみる',
  },
  'capital-flag': {
    title: '{{country}}の首都{{city}}の旗を選んでください',
  },
  'emblem-round': {
    title: '{{countryName}}の国章を選んでください',
  },
  border: {
    title: '{{countryName}}の隣国の旗を選んでください',
  },
  settings: {
    title: '設定',
    'distance-unit': '距離単位',
    theme: 'テーマ',
    language: '言語',
    'difficulty-modifiers': '難易度の修正',
    grayscale: 'グレースケールの旗',
  },
  stats: {
    title: '統計',
    played: 'プレイ回数',
    win: '勝率',
    currentStreak: '現在の連勝数',
    maxStreak: '最高連勝数',
    guessDistribution: '推測の分布',
    donate: '🇺🇦 ウクライナへの寄付 ❤️',
  },
  'how-to-play': {
    title: '遊び方',
    guess: '推測してください',
    'number-guesses': '6回の推測で。',
    'valid-country': '各推測は有効な国または地域でなければなりません。',
    'after-guess': '各推測後、目標地点までの距離、方向、近接度が表示されます。',
    examples: '例',
    'your-guess': 'あなたの推測',
    is: 'は',
    away: '目標地点から離れており、北東方向に位置します。距離が遠いため、近接度は32%です。',
    'second-guess': '2回目の推測',
    'getting-closer':
      '近づいています！{{distance}}離れており、南東方向に位置し、近接度は83%です。',
    'next-guess': '次の推測、',
    'right-guess': 'それが目標地点です！おめでとうございます',
    'new-game': '新しいゲームが',
    available: '毎日利用可能になります！',
    suggestions: '質問や提案はありますか？',
    check: 'こちらをご覧ください：',
    faq: 'Flagle FAQ',
    made: '作成者',
    ads: '広告提供：',
    privacy: 'プライバシーポリシー',
    feedback: 'フィードバック',
    hiring: '採用情報',
  },
  faq: {
    'when-new-game-available': {
      question: '新しいゲームはいつ利用可能になりますか？',
      answer:
        '新しいゲームは毎日0:00（デバイスの現地時間）に利用可能になります！',
    },
    'how-earn-bonus-round': {
      question: '次のボーナスラウンドへの進出方法は？',
      answer:
        'ボーナスラウンド1（形状）を取得するには、国を正しく推測するだけです。',
    },
    'why-data-incorrect': {
      question: 'なぜデータが間違っている、または古いのですか？',
      answer:
        'データをできるだけ迅速かつ正確に更新するよう努めています。国境が変わり、首都が移動し、地図が古くなることを知っています。間違いは完全に意図的ではなく、政治的声明ではありません。フィードバックをいただければ修正します。時間が経つにつれて、修正する必要のあるエラーは減っていきます。',
    },
    'green-yellow-squares': {
      question: '緑と黄色の四角形は何を意味しますか？',
      answer: 'これらは近接率を示しています：<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: '近接率とは何ですか？',
      answer:
        '距離を表すもう一つの方法です。<br><br>推測が世界の反対側にある場合、0%になります。<br>ターゲットにぴったり合っている場合は、100%になります！',
    },
    'how-distances-computed': {
      question: '距離はどのように計算されますか？',
      answer:
        '表示される距離は、選択された領域の中心とターゲット領域の中心との間の距離を示しています。<br><br>例えば、アメリカとカナダの間の計算された距離は約2260kmです。たとえ共通の国境があったとしても。<br><br>この選択には2つの理由があります：<br><br>• 最寄りの国境を使用する代わりに、このように距離を計算する方が技術的に非常に簡単です。<br><br>• この情報を基に、ターゲット国の大きさに関する情報を推測することができます。',
    },
    'why-not-valid-answer': {
      question: 'なぜ特定の国が有効な回答ではないのですか？',
      answer:
        '国コードリストの参照としてISO 3166-1標準を使用しています。<br><br>例えば、スコットランドはこの基準によれば英国の一部と見なされているため、有効な回答ではありません。',
    },
    'how-countries-picked': {
      question:
        '国や地域はどのように選ばれますか？すべての国が選ばれたらどうなりますか？',
      answer:
        '毎日、ランダムに国や地域が選ばれます！<br><br>したがって、永遠に毎日ランダムに1つ選ばれ続けます！<br><br>以下の2つのルールが追加されました：<br><br>• 過去7日以内に選ばれたものがある場合、5000km²未満の国や地域は選ばれません。<br>• 過去100日以内に選ばれた国は選ばれません。<br>• 昨日と同じ大陸の国は選ばれません（アフリカとアジアのみ適用）。',
    },
    'why-ads': {
      question: 'なぜデバイスに広告が表示されるのですか？',
      answer:
        '新機能の開発コスト、ホスティング、その他の費用を賄うために広告を使用しています。これまでに@teuteufには多くの提案やリクエストが寄せられましたが、すべてに対応することはできませんでした。より多くの機能や楽しいゲームプレイを提供したいと考えています。私たちの計画は、公式のモバイルアプリや、Worldleをインターネット上で最も楽しい地理クイズゲームに保つための無数のボーナス機能を提供することです！',
    },
  },
};
