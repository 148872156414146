import { Translations } from '../translations.types';

export const co: Translations = {
  flag: 'Bandera',
  shape: 'Forma',
  'Population & Currency': 'Pupulazione è Muneta',
  'Capital flag': 'Capitale',
  emblem: 'Stemma',
  neighbours: 'Vicini',
  placeholder: 'Paese\u200by, territoriu...',
  guess: 'Induvinà',
  share: 'Spartite i risultati',
  'unknown-country': 'Paese scunnisciutu!',
  copy: 'Risultati copiati in u clipboard',
  'play-bonus': 'Ghjochi a ronda bonus',
  'next-bonus': 'Prossima ronda bonus',
  'other-games': 'I nostri altri ghjochi:',
  'submit-feedback': 'Mandate un parè',
  'remaining-guesses': 'Vi ferma {{attemptsLeft}} pruvate',
  main: {
    'make-a-guess': 'Fate una supposizione per rivelà u primu tassellu',
    country: 'Paese...',
    flag: 'Bandera',
    'already-guessed': 'Avete dighjà indovinatu {{currentGuess}}',
  },
  quiz: {
    title: 'Pupulazione è Muneta',
    'estimated-population':
      'Chì ghjè a pupulazione stimata di {{countryName}}?',
    'guess-currency': 'Chì muneta si usa in {{countryName}}?',
    population: 'Pupulazione',
    currency: 'Muneta',
    micro: {
      'less-25': 'Meno di 25',
      over: 'Più di 2000',
    },
    small: {
      'first-option': '< 50 mila',
      'second-option': '50 - 500 mila',
      'third-option': '500k - 1 milione',
      'fourth-option': '1+ milione',
    },
    medium: {
      'first-option': '< 3 milioni',
      'second-option': '3 - 5 milioni',
      'third-option': '5 - 8 milioni',
      'fourth-option': '8+ milioni',
    },
    large: {
      'first-option': '< 15 milioni',
      'second-option': '15 - 25 milioni',
      'third-option': '25 - 35 milioni',
      'fourth-option': '35+ milioni',
    },
    massive: {
      'first-option': '< 60 milioni',
      'second-option': '60 - 80 milioni',
      'third-option': '80 - 100 milioni',
      'fourth-option': '100+ milioni',
    },
  },
  'shape-round': {
    title: 'Chì forma hà {{countryName}}',
    sponsored: 'Sponsorizatu da',
    like: 'Vi piace sta ronda',
    try: 'Pruvate',
  },
  'capital-flag': {
    title: 'Sceglite a bandera di a capitale di {{country}}: {{city}}',
  },
  'emblem-round': {
    title: "Sceglite l'emblema naziunale di {{countryName}}",
  },
  border: {
    title: "Sceglite a bandera d'un paese vicinu à {{countryName}}",
  },
  settings: {
    title: 'Impostazioni',
    'distance-unit': 'Unità di distanza',
    theme: 'Tema',
    language: 'Lingua',
    'difficulty-modifiers': 'Modificatori di difficultà',
    grayscale: 'Bandera in scala di grisgiu',
  },
  stats: {
    title: 'Statìstiche',
    played: 'Ghjochi fatti',
    win: 'Percentuale di vittoria',
    currentStreak: 'Serie attuale',
    maxStreak: 'Serie massima',
    guessDistribution: 'Distribuzione di supposizioni',
    donate: "🇺🇦 Donate à l'Ucraina ❤️",
  },
  'how-to-play': {
    title: 'Cumu ghjucà',
    guess: 'Induvinate u',
    'number-guesses': 'in 6 pruvati.',
    'valid-country': 'Ogni pruvata deve esse un paese o territoriu validu.',
    'after-guess':
      'Dopu ogni pruvata, riceverete a distanza, a direzzione è a vicinanza trà a vostra scelta è u locu di destinazione.',
    examples: 'Esempii',
    'your-guess': 'A vostra pruvata',
    is: 'hè',
    away: 'luntanu da u locu di destinazione, chì si trova à u nordeste, è avete solu 32% di vicinanza perchè hè abbastanza luntanu.',
    'second-guess': 'A vostra seconda pruvata',
    'getting-closer': 'si avvicina! À {{distance}}, direzzione sudeste è 83%!',
    'next-guess': 'Prossima pruvata,',
    'right-guess': 'hè u locu à indovinà! Complimenti',
    'new-game': 'Un novu',
    available: 'sarà dispunibule ogni ghjornu!',
    suggestions: 'Qualchì domanda o suggerimentu?',
    check: 'Cunsultate u',
    faq: 'FAQ Flagle',
    made: 'Fattu da',
    ads: 'Annunci da',
    privacy: 'Pulitica di privacy',
    feedback: 'Parè',
    hiring: 'Simu in cerca di staff',
  },
  faq: {
    'when-new-game-available': {
      question: 'Quandu hè dispunibule un novu ghjocu?',
      answer:
        'Un novu ghjocu hè dispunibule ogni ghjornu à 0:00, ora lucale di u vostru dispositivu!',
    },
    'how-earn-bonus-round': {
      question: "Cumu possu ottene l'accessu à a prossima manche bonus?",
      answer:
        'Per ottene a prima manche bonus (forma), basta à indovinà currettamente u paese.',
    },
    'why-data-incorrect': {
      question: 'Perchè i vostri dati sò sbagliati o obsoleti?',
      answer:
        'Provu à aghjurnà i dati u più prestu è u più precisamente pussibule. Sapemu chì e fruntiere cambianu, e capitale si spostanu, è e carte diventanu obsolete. Ogni errore hè cumplettamente involuntariu è ùn raprisenta micca una dichjarazione pulitica. Apprezzemu i vostri feedback è correggeremu i sbagli appena i cunniscimu. Cù u tempu, ci serà di menu in menu da curregge.',
    },
    'green-yellow-squares': {
      question: 'Chì significanu i quadrati verdi è gialli?',
      answer:
        'Currispondenu à a percentuale di vicinanza:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'Chì hè a percentuale di vicinanza?',
      answer:
        "Hè un altru modu per riprisentà a distanza.<br><br>Sè u vostru suppostu hè à l'altru latu di u mondu, uttenerete 0%.<br>S'ellu hè esattamente nantu à u scopu, uttenerete 100%!",
    },
    'how-distances-computed': {
      question: 'Cumu sò calculate e distanze?',
      answer:
        "E distanze mostrate currispondenu à e distanze trà i centri di u territoriu selezziunatu è quellu miratu.<br><br>Per esempiu, a distanza calculata trà i Stati Uniti è u Canada hè di circa 2260 km, ancu s'elli anu una fruntiera cumuna.<br><br>Dui motivi per sta scelta:<br><br>• Hè tecnicamente assai più faciule di calculà a distanza cusì invece d'utilizà e fruntiere più vicine.<br><br>• Pudete deduce infurmazioni nantu à a dimensione di u paese miratu grazia à sta infurmazione.",
    },
    'why-not-valid-answer': {
      question: 'Perchè un certu paese ùn hè micca una risposta valida?',
      answer:
        "Adupru u standard ISO 3166-1 cum'è riferenza per a lista di codici di i paesi.<br><br>Per esempiu, a Scozia ùn hè micca una risposta valida, perchè secondu stu standard, stu paese face parte di u Regnu Unitu.",
    },
    'how-countries-picked': {
      question:
        'Cumu sò scelti i paesi è i territorii? Chì accadrà quandu tutti i paesi seranu stati scelti?',
      answer:
        "Ogni ghjornu, un paese o un territoriu hè sceltu à l'azardu!<br><br>Dunque, continuerà à sceglie unu à casu ogni ghjornu, per sempre!<br><br>Dui reguli sò stati aghjunti:<br><br>• Ùn sceglie micca un paese o un territoriu di menu di 5.000 km² s'ellu hè statu sceltu unu cù menu di 7 ghjorni fà.<br>• Ùn sceglie micca un paese chì hè statu sceltu cù menu di 100 ghjorni fà.<br>• Ùn sceglie micca un paese di u stessu cuntinente di quellu di eri (vale solu per l'Africa è l'Asia).",
    },
    'why-ads': {
      question: "Perchè vecu publicità nant'à u mo dispositivu?",
      answer:
        "Aduprà publicità per copre i costi di sviluppu per nuove funzionalità, ospitalità è altre spese. Cù u tempu, @teuteuf hà ricevutu parechje suggerimenti è richieste chì ùn pudia micca risponde. Vulemu purtà più funziunalità è una sperienza di ghjocu più divertente per VOI. U nostru pianu hè di purtà una applicazione mobile ufficiale è numerose funziunalità bonus per mantene Worldle cum'è u ghjocu quiz geograficu più divertente nantu à Internet!",
    },
  },
};
