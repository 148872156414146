import { Translations } from '../translations.types';

export const fr: Translations = {
  flag: 'Drapeau',
  shape: 'Forme',
  'Population & Currency': 'Population & Monnaie',
  'Capital flag': 'Capitale',
  emblem: 'Emblème',
  neighbours: 'Voisins',
  placeholder: 'Pays\u200by, territoire...',
  guess: 'Deviner',
  share: 'Partager les résultats',
  'unknown-country': 'Pays inconnu!',
  copy: 'Résultats copiés dans le presse-papiers',
  'play-bonus': 'Jouer le tour bonus',
  'next-bonus': 'Tour bonus suivant',
  'other-games': 'Nos autres jeux :',
  'submit-feedback': 'Envoyer un retour',
  'remaining-guesses': 'Il vous reste {{attemptsLeft}} tentatives',
  main: {
    'make-a-guess': 'Faites une supposition pour révéler la première tuile',
    country: 'Pays...',
    flag: 'Drapeau',
    'already-guessed': 'Vous avez déjà deviné {{currentGuess}}',
  },
  quiz: {
    title: 'Population & Monnaie',
    'estimated-population':
      'Quelle est la population estimée de {{countryName}}',
    'guess-currency': 'Quelle est la monnaie utilisée à {{countryName}}',
    population: 'Population',
    currency: 'Monnaie',
    micro: {
      'less-25': 'Moins de 25',
      over: 'Plus de 2000',
    },
    small: {
      'first-option': '< 50 mille',
      'second-option': '50 - 500 mille',
      'third-option': '500k - 1 million',
      'fourth-option': '1+ million',
    },
    medium: {
      'first-option': '< 3 millions',
      'second-option': '3 - 5 millions',
      'third-option': '5 - 8 millions',
      'fourth-option': '8+ millions',
    },
    large: {
      'first-option': '< 15 millions',
      'second-option': '15 - 25 millions',
      'third-option': '25 - 35 millions',
      'fourth-option': '35+ millions',
    },
    massive: {
      'first-option': '< 60 millions',
      'second-option': '60 - 80 millions',
      'third-option': '80 - 100 millions',
      'fourth-option': '100+ millions',
    },
  },
  'shape-round': {
    title: 'Quelle est la forme de {{countryName}}',
    sponsored: 'Sponsorisé par',
    like: 'Aimez ce tour',
    try: 'Essayez',
  },
  'capital-flag': {
    title: 'Choisissez le drapeau de la capitale de {{country}} : {{city}}',
  },
  'emblem-round': {
    title: "Choisissez l'emblème national de {{countryName}}",
  },
  border: {
    title: "Choisissez le drapeau d'un pays voisin de {{countryName}}",
  },
  settings: {
    title: 'Paramètres',
    'distance-unit': 'Unité de distance',
    theme: 'Thème',
    language: 'Langue',
    'difficulty-modifiers': 'Modificateurs de difficulté',
    grayscale: 'Drapeau en niveaux de gris',
  },
  stats: {
    title: 'Statistiques',
    played: 'Joué',
    win: 'Taux de réussite %',
    currentStreak: 'Série actuelle',
    maxStreak: 'Série max',
    guessDistribution: 'Répartition des tentatives',
    donate: "🇺🇦 Faire un don à l'Ukraine ❤️",
  },
  'how-to-play': {
    title: 'Comment jouer',
    guess: 'Devinez le',
    'number-guesses': 'en 6 essais.',
    'valid-country': 'Chaque tentative doit être un pays ou territoire valide.',
    'after-guess':
      "Après chaque tentative, vous aurez la distance, la direction et la proximité entre votre choix et l'emplacement cible.",
    examples: 'Exemples',
    'your-guess': 'Votre tentative',
    is: 'est',
    away: "loin de l'emplacement cible, celui-ci est au nord-est, et vous n'avez que 32 % de proximité car c'est assez loin !",
    'second-guess': 'Votre deuxième tentative',
    'getting-closer':
      'se rapproche ! À {{distance}}, direction sud-est, et 83 % !',
    'next-guess': 'Prochaine tentative,',
    'right-guess': "c'est l'emplacement à deviner ! Félicitations",
    'new-game': 'Un nouveau',
    available: 'sera disponible chaque jour !',
    suggestions: 'Des questions ou suggestions ?',
    check: 'Consultez le',
    faq: 'FAQ Flagle',
    made: 'Créé par',
    ads: 'Publicités par',
    privacy: 'Politique de confidentialité',
    feedback: 'Retour',
    hiring: 'Nous recrutons',
  },
  faq: {
    'when-new-game-available': {
      question: 'Quand un nouveau jeu est-il disponible ?',
      answer:
        'Un nouveau jeu est disponible chaque jour à 0h00, heure locale de votre appareil !',
    },
    'how-earn-bonus-round': {
      question: 'Comment gagner le passage au prochain tour bonus ?',
      answer:
        'Pour obtenir le premier tour bonus (forme), il vous suffit de deviner correctement le pays.',
    },
    'why-data-incorrect': {
      question: 'Pourquoi vos données sont-elles incorrectes ou anciennes ?',
      answer:
        "Nous essayons de mettre à jour nos données aussi rapidement et précisément que possible. Nous savons que les frontières changent, les capitales bougent et les cartes deviennent obsolètes. Toute erreur est totalement involontaire et n'est pas une déclaration politique. Nous apprécions vos retours et corrigerons les erreurs dès que nous en aurons connaissance. Au fil du temps, il y aura de moins en moins d'erreurs.",
    },
    'green-yellow-squares': {
      question: 'Que signifient les carrés verts et jaunes ?',
      answer:
        'Ils correspondent au pourcentage de proximité :<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: "Qu'est-ce que le pourcentage de proximité ?",
      answer:
        "C'est une autre façon de représenter la distance.<br><br>Si votre réponse est à l'opposé du monde, vous obtiendrez 0%.<br>Si c'est exactement sur la cible, vous obtiendrez 100% !",
    },
    'how-distances-computed': {
      question: 'Comment les distances sont-elles calculées ?',
      answer:
        "Les distances affichées correspondent aux distances entre le centre du territoire sélectionné et celui de la cible.<br><br>Par exemple, la distance calculée entre les États-Unis et le Canada est d'environ 2260 km, même s'ils partagent une frontière commune.<br><br>Deux raisons pour ce choix :<br><br>• C'est techniquement beaucoup plus simple à calculer ainsi qu'en utilisant les frontières les plus proches.<br><br>• Cela vous permet de déduire des informations sur la taille du pays cible grâce à ces données.",
    },
    'why-not-valid-answer': {
      question: "Pourquoi un pays donné n'est-il pas une réponse valide ?",
      answer:
        "J'utilise la norme ISO 3166-1 comme référence pour la liste des codes des pays.<br><br>Par exemple, l'Écosse n'est pas une réponse valide, car selon cette norme, ce pays fait partie du Royaume-Uni.",
    },
    'how-countries-picked': {
      question:
        'Comment les pays et territoires sont-ils choisis ? Que se passe-t-il quand tous les pays ont été sélectionnés ?',
      answer:
        "Chaque jour, un pays ou un territoire est choisi aléatoirement !<br><br>Donc, cela continuera à en choisir un au hasard tous les jours, pour toujours !<br><br>Deux règles ont été ajoutées :<br><br>• Il ne sélectionne pas un pays ou territoire de moins de 5 000 km² si un autre a été choisi il y a moins de 7 jours.<br>• Il ne sélectionne pas un pays déjà choisi il y a moins de 100 jours.<br>• Il ne sélectionne pas un pays du même continent que celui d'hier (s'applique uniquement à l'Afrique et à l'Asie).",
    },
    'why-ads': {
      question: 'Pourquoi vois-je des publicités sur mon appareil ?',
      answer:
        "Nous utilisons des publicités pour couvrir les coûts de développement des nouvelles fonctionnalités, ainsi que l'hébergement et d'autres dépenses. Au fil du temps, @teuteuf a reçu de nombreuses suggestions et demandes qu'il ne pouvait pas satisfaire. Nous voulons apporter plus de fonctionnalités et de gameplay amusants à VOUS. Notre plan est de vous offrir une application mobile officielle ainsi que d'innombrables fonctionnalités bonus pour que Worldle reste le quiz géographique le plus amusant sur Internet !",
    },
  },
};
