import { Translations } from '../translations.types';

export const de: Translations = {
  flag: 'Flagge',
  shape: 'Form',
  'Population & Currency': 'Bevölkerung und Währung',
  'Capital flag': 'Hauptstadt',
  emblem: 'Wappen',
  neighbours: 'Nachbarn',
  placeholder: 'Land\u200by, Gebiet...',
  guess: 'Raten',
  share: 'Ergebnisse teilen',
  'unknown-country': 'Unbekanntes Land!',
  copy: 'Ergebnisse in die Zwischenablage kopiert',
  'play-bonus': 'Bonusrunde spielen',
  'next-bonus': 'Nächste Bonusrunde',
  'other-games': 'Unsere anderen Spiele:',
  'submit-feedback': 'Feedback senden',
  'remaining-guesses': 'Sie haben noch {{attemptsLeft}} Versuche',
  main: {
    'make-a-guess': 'Machen Sie einen Tipp, um das erste Feld aufzudecken',
    country: 'Land...',
    flag: 'Flagge',
    'already-guessed': 'Sie haben bereits {{currentGuess}} geraten',
  },
  quiz: {
    title: 'Bevölkerung und Währung',
    'estimated-population':
      'Wie hoch ist die geschätzte Bevölkerung von {{countryName}}?',
    'guess-currency': 'Welche Währung wird in {{countryName}} verwendet?',
    population: 'Bevölkerung',
    currency: 'Währung',
    micro: {
      'less-25': 'Weniger als 25',
      over: 'Über 2000',
    },
    small: {
      'first-option': '< 50 Tausend',
      'second-option': '50 - 500 Tausend',
      'third-option': '500k - 1 Million',
      'fourth-option': '1+ Million',
    },
    medium: {
      'first-option': '< 3 Millionen',
      'second-option': '3 - 5 Millionen',
      'third-option': '5 - 8 Millionen',
      'fourth-option': '8+ Millionen',
    },
    large: {
      'first-option': '< 15 Millionen',
      'second-option': '15 - 25 Millionen',
      'third-option': '25 - 35 Millionen',
      'fourth-option': '35+ Millionen',
    },
    massive: {
      'first-option': '< 60 Millionen',
      'second-option': '60 - 80 Millionen',
      'third-option': '80 - 100 Millionen',
      'fourth-option': '100+ Millionen',
    },
  },
  'shape-round': {
    title: 'Wie ist die Form von {{countryName}}',
    sponsored: 'Gesponsert von',
    like: 'Gefällt Ihnen diese Runde',
    try: 'Probieren',
  },
  'capital-flag': {
    title: 'Wählen Sie die Flagge der Hauptstadt von {{country}}: {{city}}',
  },
  'emblem-round': {
    title: 'Wählen Sie das Wappen von {{countryName}}',
  },
  border: {
    title: 'Wählen Sie die Flagge eines Nachbarlandes von {{countryName}}',
  },
  settings: {
    title: 'Einstellungen',
    'distance-unit': 'Entfernungseinheit',
    theme: 'Thema',
    language: 'Sprache',
    'difficulty-modifiers': 'Schwierigkeitsmodifikatoren',
    grayscale: 'Flagge in Graustufen',
  },
  stats: {
    title: 'Statistiken',
    played: 'Gespielt',
    win: 'Gewinnrate %',
    currentStreak: 'Aktuelle Serie',
    maxStreak: 'Maximale Serie',
    guessDistribution: 'Verteilung der Versuche',
    donate: '🇺🇦 Spenden an die Ukraine ❤️',
  },
  'how-to-play': {
    title: 'Spielanleitung',
    guess: 'Erraten Sie den',
    'number-guesses': 'in 6 Versuchen.',
    'valid-country': 'Jeder Tipp muss ein gültiges Land oder Gebiet sein.',
    'after-guess':
      'Nach jedem Tipp erhalten Sie die Entfernung, die Richtung und die Nähe zwischen Ihrer Auswahl und dem Zielort.',
    examples: 'Beispiele',
    'your-guess': 'Ihr Tipp',
    is: 'ist',
    away: 'vom Zielort entfernt, der Zielort liegt nordöstlich und Sie haben nur 32 % Nähe, da es ziemlich weit entfernt ist.',
    'second-guess': 'Ihr zweiter Tipp',
    'getting-closer':
      'kommt näher! {{distance}} entfernt, Richtung Südosten und 83 %!',
    'next-guess': 'Nächster Tipp,',
    'right-guess': 'es ist der Zielort! Glückwunsch',
    'new-game': 'Ein neues',
    available: 'wird jeden Tag verfügbar sein!',
    suggestions: 'Fragen oder Vorschläge?',
    check: 'Sehen Sie sich die',
    faq: 'Flagle-FAQ',
    made: 'Erstellt von',
    ads: 'Werbung von',
    privacy: 'Datenschutzrichtlinie',
    feedback: 'Feedback',
    hiring: 'Wir stellen ein',
  },
  faq: {
    'when-new-game-available': {
      question: 'Wann ist ein neues Spiel verfügbar?',
      answer:
        'Ein neues Spiel ist jeden Tag um 0:00 Uhr Ortszeit deines Geräts verfügbar!',
    },
    'how-earn-bonus-round': {
      question: 'Wie erreiche ich die nächste Bonusrunde?',
      answer:
        'Um die erste Bonusrunde (Form) zu erhalten, musst du einfach das Land richtig erraten.',
    },
    'why-data-incorrect': {
      question: 'Warum sind deine Daten falsch oder veraltet?',
      answer:
        'Wir versuchen, unsere Daten so schnell und genau wie möglich zu aktualisieren. Wir wissen, dass sich Grenzen ändern, Hauptstädte verlegen und Karten veraltet sind. Jeder Fehler ist völlig unbeabsichtigt und keine politische Aussage. Wir schätzen dein Feedback und werden Fehler beheben, sobald wir von ihnen erfahren. Mit der Zeit wird es immer weniger zu korrigieren geben.',
    },
    'green-yellow-squares': {
      question: 'Was bedeuten die grünen und gelben Quadrate?',
      answer:
        'Sie entsprechen dem Nähe-Prozentsatz:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'Was ist der Nähe-Prozentsatz?',
      answer:
        'Es ist eine andere Möglichkeit, die Entfernung darzustellen.<br><br>Wenn deine Vermutung auf der anderen Seite der Welt liegt, erhältst du 0%.<br>Liegt sie genau auf dem Ziel, erhältst du 100%!',
    },
    'how-distances-computed': {
      question: 'Wie werden die Entfernungen berechnet?',
      answer:
        'Die angezeigten Entfernungen entsprechen den Distanzen zwischen den Zentren des ausgewählten und des Zielgebiets.<br><br>Beispielsweise beträgt die berechnete Entfernung zwischen den Vereinigten Staaten und Kanada etwa 2260 km, obwohl sie eine gemeinsame Grenze haben.<br><br>Zwei Gründe für diese Wahl:<br><br>• Es ist technisch viel einfacher, die Entfernung auf diese Weise zu berechnen, anstatt die nächstgelegenen Grenzen zu verwenden.<br><br>• Du kannst Informationen über die Größe des Ziellandes anhand dieser Daten ableiten.',
    },
    'why-not-valid-answer': {
      question: 'Warum ist ein bestimmtes Land keine gültige Antwort?',
      answer:
        'Ich verwende den ISO-3166-1-Standard als Referenz für die Ländercodeliste.<br><br>Zum Beispiel ist Schottland keine gültige Antwort, da dieses Land laut Standard Teil des Vereinigten Königreichs ist.',
    },
    'how-countries-picked': {
      question:
        'Wie werden Länder und Territorien ausgewählt? Was passiert, wenn alle Länder ausgewählt wurden?',
      answer:
        'Jeden Tag wird ein Land oder Territorium zufällig ausgewählt!<br><br>Das wird immer so weitergehen, jeden Tag ein zufälliges.<br><br>Zwei Regeln wurden hinzugefügt:<br><br>• Es wird kein Land oder Territorium mit weniger als 5000 km² ausgewählt, wenn in den letzten 7 Tagen bereits eines ausgewählt wurde.<br>• Es wird kein Land ausgewählt, das in den letzten 100 Tagen bereits ausgewählt wurde.<br>• Es wird kein Land vom selben Kontinent wie gestern ausgewählt (gilt nur für Afrika und Asien).',
    },
    'why-ads': {
      question: 'Warum sehe ich Werbung auf meinem Gerät?',
      answer:
        'Wir verwenden Werbung, um die Entwicklungskosten für neue Funktionen sowie Hosting und andere Ausgaben zu decken. Im Laufe der Zeit hat @teuteuf viele Vorschläge und Anfragen erhalten, die er nicht erfüllen konnte. Wir möchten dir mehr Funktionen und ein unterhaltsameres Spielerlebnis bieten. Unser Plan ist es, dir eine offizielle mobile App sowie zahlreiche Bonusfunktionen zu bringen, um Worldle zum unterhaltsamsten Geografie-Quizspiel im Internet zu machen!',
    },
  },
};
