import { Translations } from '../translations.types';

export const pt: Translations = {
  flag: 'Bandeira',
  shape: 'Forma',
  'Population & Currency': 'População e Moeda',
  'Capital flag': 'Capital',
  emblem: 'Emblema',
  neighbours: 'Vizinhos',
  placeholder: 'País\u200b, território...',
  guess: 'Adivinhar',
  share: 'Compartilhar resultados',
  'unknown-country': 'País desconhecido!',
  copy: 'Resultados copiados para a área de transferência',
  'play-bonus': 'Jogar rodada bônus',
  'next-bonus': 'Próxima rodada bônus',
  'other-games': 'Nossos outros jogos:',
  'submit-feedback': 'Enviar feedback',
  'remaining-guesses': 'Você tem {{attemptsLeft}} tentativas restantes',
  main: {
    'make-a-guess': 'Faça uma suposição para revelar o primeiro bloco',
    country: 'País...',
    flag: 'Bandeira',
    'already-guessed': 'Você já adivinhou {{currentGuess}}',
  },
  quiz: {
    title: 'População e Moeda',
    'estimated-population': 'Qual é a população estimada de {{countryName}}?',
    'guess-currency': 'Qual é a moeda usada em {{countryName}}?',
    population: 'População',
    currency: 'Moeda',
    micro: {
      'less-25': 'Menos de 25',
      over: 'Mais de 2000',
    },
    small: {
      'first-option': '< 50 mil',
      'second-option': '50 - 500 mil',
      'third-option': '500 mil - 1 milhão',
      'fourth-option': '1+ milhão',
    },
    medium: {
      'first-option': '< 3 milhões',
      'second-option': '3 - 5 milhões',
      'third-option': '5 - 8 milhões',
      'fourth-option': '8+ milhões',
    },
    large: {
      'first-option': '< 15 milhões',
      'second-option': '15 - 25 milhões',
      'third-option': '25 - 35 milhões',
      'fourth-option': '35+ milhões',
    },
    massive: {
      'first-option': '< 60 milhões',
      'second-option': '60 - 80 milhões',
      'third-option': '80 - 100 milhões',
      'fourth-option': '100+ milhões',
    },
  },
  'shape-round': {
    title: 'Qual é a forma de {{countryName}}',
    sponsored: 'Patrocinado por',
    like: 'Gostou desta rodada?',
    try: 'Experimente',
  },
  'capital-flag': {
    title: 'Escolha a bandeira da capital de {{country}}: {{city}}',
  },
  'emblem-round': {
    title: 'Escolha o emblema nacional de {{countryName}}',
  },
  border: {
    title: 'Escolha a bandeira de um país vizinho de {{countryName}}',
  },
  settings: {
    title: 'Configurações',
    'distance-unit': 'Unidade de distância',
    theme: 'Tema',
    language: 'Idioma',
    'difficulty-modifiers': 'Modificadores de dificuldade',
    grayscale: 'Bandeira em escala de cinza',
  },
  stats: {
    title: 'Estatísticas',
    played: 'Jogadas',
    win: 'Percentual de vitórias',
    currentStreak: 'Sequência atual',
    maxStreak: 'Maior sequência',
    guessDistribution: 'Distribuição de palpites',
    donate: '🇺🇦 Doe para a Ucrânia ❤️',
  },
  'how-to-play': {
    title: 'Como jogar',
    guess: 'Adivinhe o',
    'number-guesses': 'em 6 tentativas.',
    'valid-country': 'Cada palpite deve ser um país ou território válido.',
    'after-guess':
      'Após cada palpite, você verá a distância, a direção e a proximidade da sua escolha em relação ao local-alvo.',
    examples: 'Exemplos',
    'your-guess': 'Seu palpite',
    is: 'está',
    away: 'longe do local-alvo, que está na direção nordeste e a proximidade é de apenas 32%, pois está bem distante.',
    'second-guess': 'Seu segundo palpite',
    'getting-closer':
      'está se aproximando! {{distance}} de distância, direção sudeste e 83% de proximidade!',
    'next-guess': 'Próximo palpite,',
    'right-guess': 'é o local correto! Parabéns',
    'new-game': 'Um novo jogo',
    available: 'estará disponível todos os dias!',
    suggestions: 'Dúvidas ou sugestões?',
    check: 'Confira o',
    faq: 'FAQ do Flagle',
    made: 'Feito por',
    ads: 'Anúncios por',
    privacy: 'Política de privacidade',
    feedback: 'Feedback',
    hiring: 'Estamos contratando',
  },
  faq: {
    'when-new-game-available': {
      question: 'Quando um novo jogo estará disponível?',
      answer:
        'Um novo jogo estará disponível todos os dias às 0h00, horário local do seu dispositivo!',
    },
    'how-earn-bonus-round': {
      question: 'Como ganho acesso à próxima rodada bônus?',
      answer:
        'Para acessar a primeira rodada bônus (forma), você só precisa adivinhar o país corretamente.',
    },
    'why-data-incorrect': {
      question: 'Por que seus dados estão incorretos ou desatualizados?',
      answer:
        'Estamos tentando atualizar nossos dados o mais rápido e preciso possível. Sabemos que as fronteiras mudam, as capitais se movem e os mapas ficam desatualizados. Qualquer erro é completamente involuntário e não representa uma declaração política. Agradecemos o feedback e corrigiremos os erros assim que tomarmos conhecimento deles. Com o tempo, haverá cada vez menos para corrigir.',
    },
    'green-yellow-squares': {
      question: 'O que significam os quadrados verdes e amarelos?',
      answer:
        'Eles correspondem ao percentual de proximidade:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'O que é o percentual de proximidade?',
      answer:
        'É outra forma de representar a distância.<br><br>Se o seu palpite estiver do outro lado do mundo, você obterá 0%.<br>Se estiver exatamente no alvo, você obterá 100%!',
    },
    'how-distances-computed': {
      question: 'Como as distâncias são calculadas?',
      answer:
        'As distâncias exibidas correspondem às distâncias entre os centros do território selecionado e o alvo.<br><br>Por exemplo, a distância calculada entre os Estados Unidos e o Canadá é de aproximadamente 2260 km, mesmo que compartilhem uma fronteira comum.<br><br>Duas razões para essa escolha:<br><br>• É tecnicamente muito mais fácil calcular a distância dessa forma do que usar as fronteiras mais próximas.<br><br>• Você pode deduzir informações sobre o tamanho do país-alvo com base nessa informação.',
    },
    'why-not-valid-answer': {
      question: 'Por que um determinado país não é uma resposta válida?',
      answer:
        'Uso o padrão ISO 3166-1 como referência para a lista de códigos de países.<br><br>Por exemplo, a Escócia não é uma resposta válida, porque, de acordo com esse padrão, esse país faz parte do Reino Unido.',
    },
    'how-countries-picked': {
      question:
        'Como os países e territórios são escolhidos? O que acontece quando todos os países já foram escolhidos?',
      answer:
        'Todos os dias, um país ou território é escolhido aleatoriamente!<br><br>Portanto, continuará escolhendo um aleatório todos os dias, para sempre!<br><br>Duas regras foram adicionadas:<br><br>• Não escolhe um país ou território com menos de 5.000 km² quando já foi escolhido um com essas características nos últimos 7 dias.<br>• Não escolhe um país que já tenha sido escolhido nos últimos 100 dias.<br>• Não escolhe um país do mesmo continente que o de ontem (aplica-se apenas à África e à Ásia).',
    },
    'why-ads': {
      question: 'Por que vejo anúncios no meu dispositivo?',
      answer:
        'Usamos anúncios para cobrir os custos de desenvolvimento de novas funcionalidades, além de hospedagem e outras despesas. Com o tempo, @teuteuf recebeu muitas sugestões e pedidos que não pôde atender. Queremos trazer mais recursos e jogabilidade divertida para VOCÊ. Nosso plano é oferecer um aplicativo móvel oficial, bem como inúmeras funcionalidades bônus, para manter o Worldle como o jogo de quiz geográfico mais divertido da internet!',
    },
  },
};
