import { AnswerTag } from '@teuteuf-games/answer-tag';
import { InputForm } from '@teuteuf-games/input-form';
import { getCompassDirection, getDistance } from 'geolib';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FlagGrid } from '../../components/FlagGrid';
import { GuessList } from '../../components/GuessList';
import { RoundEndedButtons } from '../../components/RoundEndedButtons';
import {
  MAX_ATTEMPTS,
  TILE_COUNT,
  TILES_REVEALED_AT_START,
} from '../../constants';
import { useConfettiThrower } from '../../hooks/useConfettiThrower';
import { useDailySeed } from '../../hooks/useDailySeed';
import { useGuessHistory } from '../../hooks/useGuessHistory';
import i18n from '../../i18n';
import { useTodaysCountry } from '../../providers/TodaysCountryProvider';
import { getCountryName } from '../../utils/countryName';
import { shuffleWithSeed } from '../../utils/shuffleWithSeed';

const TILE_INDICES = Array.from({ length: TILE_COUNT }, (_, i) => i);

export function MainGameRoute() {
  const { t } = useTranslation();
  const { todaysCountry, countryList, rounds, saveCompletedRound } =
    useTodaysCountry();
  const [flippedArray, setFlippedArray] = useState(Array(6).fill(false));
  const dayString = useDailySeed();
  const [end, setEnd] = useState(false);
  const [guessHistory, addGuess] = useGuessHistory();
  const guesses = useMemo(
    () => guessHistory[dayString] || [],
    [guessHistory, dayString],
  );
  const [randomOrder, setRandomOrder] = useState(() =>
    shuffleWithSeed(TILE_INDICES, dayString).slice(guesses.length),
  );
  const countyInputRef = useRef < HTMLInputElement > undefined;
  const [currentGuess, setCurrentGuess] = useState('');

  const trueCountry = useMemo(
    () => getCountryName(i18n.language, todaysCountry),
    [todaysCountry],
  );

  const allCountryNames = useMemo(
    () => countryList.map((c) => c.name),
    [countryList],
  );

  const revealRandomTile = useCallback(() => {
    const [tile] = randomOrder;
    setRandomOrder(randomOrder.slice(1));
    setFlippedArray((currArray) => {
      const newFlipped = [...currArray];
      newFlipped[tile] = true;
      return newFlipped;
    });
    return tile;
  }, [setFlippedArray, randomOrder]);

  const getRemainingTiles = useCallback(() => {
    const remainingTiles = [];
    const usedTiles = guesses.map((guess) => guess.tile);
    for (const i of TILE_INDICES) {
      if (!usedTiles.includes(i)) {
        remainingTiles.push(i);
      }
    }
    return remainingTiles;
  }, [guesses]);

  const revealTiles = useCallback(() => {
    setFlippedArray((currFlipped) => {
      const newFlipped = [...currFlipped];

      for (const guess of guesses) {
        newFlipped[guess.tile] = true;
      }
      return newFlipped;
    });
  }, [setFlippedArray, guesses]);

  const throwConfetti = useConfettiThrower();

  useEffect(() => {
    if (end) return;
    revealTiles();
    getRemainingTiles();
    const lastGuess = guesses[guesses.length - 1];
    if (guesses.length >= MAX_ATTEMPTS || lastGuess?.distance === 0) {
      saveCompletedRound('Flag');
      setEnd(true);
      setFlippedArray(Array(6).fill(true));
      if (guesses[guesses.length - 1].distance === 0) {
        toast(`🎉 ${trueCountry} 🎉`, { delay: 2000, autoClose: 3000 });
        setTimeout(throwConfetti, 2000);
      } else {
        toast(`🤔 ${trueCountry} 🤔`, { delay: 2000, autoClose: 3000 });
      }
    }
  }, [
    guesses,
    trueCountry,
    getRemainingTiles,
    revealTiles,
    throwConfetti,
    end,
    saveCompletedRound,
  ]);

  // reveal the first tile when the game starts
  useEffect(() => {
    if (randomOrder.length < 6 || !TILES_REVEALED_AT_START) return;

    setFlippedArray((prev) => {
      const newFlippedArray = [...prev];
      for (let i = 0; i < TILES_REVEALED_AT_START; i++) {
        newFlippedArray[randomOrder[i]] = true;
      }
      return newFlippedArray;
    });

    setRandomOrder((randomOrder) => randomOrder.slice(1));
  }, [setFlippedArray, setRandomOrder, randomOrder]);

  const onGuess = useCallback(
    (e) => {
      e.preventDefault();
      if (!countryList) return;

      const guessedCountry = countryList.find(
        (c) => c.name.toUpperCase() === currentGuess.toUpperCase(),
      );

      if (guessedCountry == null) {
        toast.error(t('unknown-country'), {
          autoClose: 3000,
        });
        setCurrentGuess('');
        return;
      }

      if (
        guesses.findIndex(
          (g) => g.name.toUpperCase() === currentGuess.toUpperCase(),
        ) !== -1
      ) {
        setCurrentGuess('');
        return toast.error(t('main.already-guessed', { currentGuess }), {
          autoClose: 3000,
        });
      }
      const tileNum = revealRandomTile();
      const { ...guessGeo } = countryList.find(
        (c) => c.name.toUpperCase() === currentGuess.toUpperCase(),
      );
      const { ...answerGeo } = todaysCountry;
      addGuess({
        name: currentGuess.toUpperCase(),
        distance: getDistance(guessGeo, answerGeo),
        direction: getCompassDirection(guessGeo, answerGeo),
        tile: tileNum,
      });
      setCurrentGuess('');
    },
    [
      guesses,
      revealRandomTile,
      countryList,
      todaysCountry,
      addGuess,
      currentGuess,
      t,
    ],
  );

  const currentRound = rounds.find((r) => r.route === '/');
  const nextRoundIndex = currentRound ? rounds.indexOf(currentRound) + 1 : 0;

  return (
    <>
      <div className="flex flex-col w-full max-w-lg">
        <FlagGrid
          end={end}
          countryInfo={{ code: todaysCountry.code }}
          flippedArray={flippedArray}
        ></FlagGrid>
        {end && (
          <div className="flex w-full items-center justify-center">
            <AnswerTag
              answerState={
                guesses.length && guesses[guesses.length - 1].distance === 0
                  ? 'right'
                  : 'default'
              }
              text={
                <>
                  {t('main.flag')}:&nbsp;
                  <span className="font-semibold">
                    {trueCountry?.toUpperCase() ?? ''}
                  </span>
                </>
              }
              textSize="text-md"
              padding="py-1 px-2"
              margin="mt-3"
            />
          </div>
        )}

        <p className="my-2 text-sm">
          {TILES_REVEALED_AT_START === 0 &&
            guesses?.length === 0 &&
            t('main.make-a-guess')}
          &nbsp;
        </p>
        {!end && (
          <div className="text-start">
            <InputForm
              handleSubmit={onGuess}
              inputRef={countyInputRef}
              currentGuess={currentGuess}
              setCurrentGuess={setCurrentGuess}
              autosuggestValues={allCountryNames?.sort() ?? []}
              placeholderText={t('main.country')}
              emojiButtonProps={{
                emoji: '🌍',
                label: t('guess'),
              }}
            />
          </div>
        )}
        <GuessList
          guesses={guesses}
          maxTryCount={MAX_ATTEMPTS}
          gameEnded={end}
          inputRef={countyInputRef}
        />
      </div>
      {end && (
        <div className="my-5 flex w-full">
          <RoundEndedButtons nextBonusRoundIndex={nextRoundIndex} />
        </div>
      )}
    </>
  );
}
