import { Translations } from '../translations.types';

export const nl: Translations = {
  flag: 'Vlag',
  shape: 'Vorm',
  'Population & Currency': 'Bevolking en Munteenheid',
  'Capital flag': 'Hoofdstad',
  emblem: 'Embleem',
  neighbours: 'Buren',
  placeholder: 'Land\u200b, gebied...',
  guess: 'Raden',
  share: 'Deel resultaten',
  'unknown-country': 'Onbekend land!',
  copy: 'Resultaten gekopieerd naar klembord',
  'play-bonus': 'Speel bonusronde',
  'next-bonus': 'Volgende bonusronde',
  'other-games': 'Onze andere spellen:',
  'submit-feedback': 'Feedback versturen',
  'remaining-guesses': 'Je hebt nog {{attemptsLeft}} pogingen',
  main: {
    'make-a-guess': 'Doe een gok om de eerste tegel te onthullen',
    country: 'Land...',
    flag: 'Vlag',
    'already-guessed': 'Je hebt al {{currentGuess}} geraden',
  },
  quiz: {
    title: 'Bevolking en Munteenheid',
    'estimated-population':
      'Wat is de geschatte bevolking van {{countryName}}?',
    'guess-currency': 'Welke munteenheid wordt gebruikt in {{countryName}}?',
    population: 'Bevolking',
    currency: 'Munteenheid',
    micro: {
      'less-25': 'Minder dan 25',
      over: 'Meer dan 2000',
    },
    small: {
      'first-option': '< 50 duizend',
      'second-option': '50 - 500 duizend',
      'third-option': '500k - 1 miljoen',
      'fourth-option': '1+ miljoen',
    },
    medium: {
      'first-option': '< 3 miljoen',
      'second-option': '3 - 5 miljoen',
      'third-option': '5 - 8 miljoen',
      'fourth-option': '8+ miljoen',
    },
    large: {
      'first-option': '< 15 miljoen',
      'second-option': '15 - 25 miljoen',
      'third-option': '25 - 35 miljoen',
      'fourth-option': '35+ miljoen',
    },
    massive: {
      'first-option': '< 60 miljoen',
      'second-option': '60 - 80 miljoen',
      'third-option': '80 - 100 miljoen',
      'fourth-option': '100+ miljoen',
    },
  },
  'shape-round': {
    title: 'Wat is de vorm van {{countryName}}',
    sponsored: 'Gesponsord door',
    like: 'Vind je deze ronde leuk?',
    try: 'Probeer het',
  },
  'capital-flag': {
    title: 'Kies de vlag van de hoofdstad van {{country}}: {{city}}',
  },
  'emblem-round': {
    title: 'Kies het nationale embleem van {{countryName}}',
  },
  border: {
    title: 'Kies de vlag van een buurland van {{countryName}}',
  },
  settings: {
    title: 'Instellingen',
    'distance-unit': 'Afstandseenheid',
    theme: 'Thema',
    language: 'Taal',
    'difficulty-modifiers': 'Moeilijkheidsaanpassingen',
    grayscale: 'Grijstinten vlag',
  },
  stats: {
    title: 'Statistieken',
    played: 'Gespeeld',
    win: 'Winstpercentage',
    currentStreak: 'Huidige reeks',
    maxStreak: 'Langste reeks',
    guessDistribution: 'Verdeling van pogingen',
    donate: '🇺🇦 Doneer aan Oekraïne ❤️',
  },
  'how-to-play': {
    title: 'Hoe te spelen',
    guess: 'Raad de',
    'number-guesses': 'in 6 pogingen.',
    'valid-country': 'Elke gok moet een geldig land of gebied zijn.',
    'after-guess':
      'Na elke gok zie je de afstand, richting en nabijheid van jouw gok tot de doelbestemming.',
    examples: 'Voorbeelden',
    'your-guess': 'Jouw gok',
    is: 'is',
    away: 'ver van de doelbestemming, het ligt in de noordoostelijke richting en je hebt slechts 32% nabijheid omdat het vrij ver weg is.',
    'second-guess': 'Je tweede gok',
    'getting-closer':
      'komt dichterbij! {{distance}} verwijderd, zuidoostelijke richting en 83% nabijheid!',
    'next-guess': 'Volgende gok,',
    'right-guess': 'het is de bestemming! Gefeliciteerd',
    'new-game': 'Een nieuw spel',
    available: 'is elke dag beschikbaar!',
    suggestions: 'Vragen of suggesties?',
    check: 'Bekijk de',
    faq: 'Flagle FAQ',
    made: 'Gemaakt door',
    ads: 'Advertenties door',
    privacy: 'Privacybeleid',
    feedback: 'Feedback',
    hiring: 'Wij zijn aan het werven',
  },
  faq: {
    'when-new-game-available': {
      question: 'Wanneer is er een nieuw spel beschikbaar?',
      answer:
        'Elke dag is er om 0:00 uur lokale tijd van je apparaat een nieuw spel beschikbaar!',
    },
    'how-earn-bonus-round': {
      question: 'Hoe verdien ik toegang tot de volgende bonusronde?',
      answer:
        'Om de eerste bonusronde (vorm) te krijgen, hoef je alleen maar het juiste land te raden.',
    },
    'why-data-incorrect': {
      question: 'Waarom zijn je gegevens onjuist of verouderd?',
      answer:
        'We proberen onze gegevens zo snel en nauwkeurig mogelijk bij te werken. We weten dat grenzen veranderen, hoofdsteden verplaatsen en kaarten verouderen. Elke fout is volledig onbedoeld en vormt geen politieke uitspraak. We waarderen je feedback en zullen fouten corrigeren zodra we ervan horen. Na verloop van tijd zullen er steeds minder fouten te corrigeren zijn.',
    },
    'green-yellow-squares': {
      question: 'Wat betekenen de groene en gele vierkanten?',
      answer:
        'Ze komen overeen met het nabijheidspercentage:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'Wat is het nabijheidspercentage?',
      answer:
        'Het is een andere manier om de afstand weer te geven.<br><br>Als je gok aan de andere kant van de wereld ligt, krijg je 0%.<br>Als het precies op het doelwit ligt, krijg je 100%!',
    },
    'how-distances-computed': {
      question: 'Hoe worden de afstanden berekend?',
      answer:
        'De weergegeven afstanden komen overeen met de afstanden tussen de centra van het geselecteerde gebied en het doelgebied.<br><br>Bijvoorbeeld, de berekende afstand tussen de Verenigde Staten en Canada is ongeveer 2260 km, zelfs als ze een gemeenschappelijke grens hebben.<br><br>Twee redenen voor deze keuze:<br><br>• Het is technisch veel eenvoudiger om de afstand op deze manier te berekenen dan met behulp van de dichtstbijzijnde grenzen.<br><br>• Je kunt informatie afleiden over de grootte van het doelland dankzij deze informatie.',
    },
    'why-not-valid-answer': {
      question: 'Waarom is een bepaald land geen geldige antwoord?',
      answer:
        'Ik gebruik de ISO 3166-1-standaard als referentie voor de lijst met landcodes.<br><br>Bijvoorbeeld, Schotland is geen geldig antwoord, omdat dit land volgens deze standaard deel uitmaakt van het Verenigd Koninkrijk.',
    },
    'how-countries-picked': {
      question:
        'Hoe worden landen en gebieden gekozen? Wat gebeurt er als alle landen zijn gekozen?',
      answer:
        'Elke dag wordt er willekeurig een land of gebied gekozen!<br><br>Dus het blijft elke dag willekeurig een kiezen, voor altijd!<br><br>Er zijn twee regels toegevoegd:<br><br>• Er wordt geen land of gebied van minder dan 5.000 km² gekozen als er in de afgelopen 7 dagen al een is gekozen.<br>• Er wordt geen land gekozen dat in de afgelopen 100 dagen al is gekozen.<br>• Er wordt geen land gekozen van hetzelfde continent als gisteren (dit geldt alleen voor Afrika en Azië).',
    },
    'why-ads': {
      question: 'Waarom zie ik advertenties op mijn apparaat?',
      answer:
        'We gebruiken advertenties om de ontwikkelingskosten voor nieuwe functies, hosting en andere uitgaven te dekken. In de loop der tijd heeft @teuteuf veel suggesties en verzoeken ontvangen die hij niet kon vervullen. We willen jou meer functies en een leukere spelervaring bieden. Ons plan is om een officiële mobiele app en talloze bonusfuncties te lanceren om Worldle het leukste geografiequiz-spel op internet te houden!',
    },
  },
};
